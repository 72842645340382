/* StudentForm.css */

.container {
    max-width: 600px;
    margin-top: 2rem;
    padding: 2rem;
  }
  
  .form-container {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 8px;
    background-color: #ffffff;
  }
  
  .form-label {
    font-weight: bold;
  }
  
  .submit-button {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
    cursor: pointer;
  }
  
  .submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .message {
    margin-top: 1rem;
    text-align: center;
  }
