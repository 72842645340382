/* 4.06 - Policy - Start
================================================== */
.policy_section {
	.iconbox_block.layout_icon_left {
		padding: 0;
		box-shadow: none;
		transform: unset;
		background-color: transparent;
	}
}
/* 4.06 - Policy - End
================================================== */